import React, { useState, useEffect } from "react";
import "../styles/OurTherapists.css";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import stephanie_knueppel from "../images/therapists/Stephanie Knueppel, LPC 1.jpg";
import kelly_everson from "../images/therapists/Kelley Everson, LPC 1.jpg";
import sam_ball from "../images/therapists/Sam Ball, LCSW 1.jpg";
import jenniffer_beu from "../images/therapists/Jennifer-Beu.jpg";
import audrey_buchanan from "../images/therapists/Audrey Buchanan, APSW 2.jpg";
import kenzie_dodge from "../images/therapists/Kenzie Dodge, APSW 1.jpg";
import nicholas_dubois_rose from "../images/therapists/Nicholas Dubois-Rose, APSW 1.jpg";
import jennifer_ellenson from "../images/therapists/Jennifer Ellenson, LPC-IT 1.jpg";
import david_fischer from "../images/therapists/David Fischer, LCSW 1.jpg";
import garth_fisher from "../images/therapists/Garth Fisher, LCSW 1.jpg";
import lindsey_friedrich from "../images/therapists/Lindsey Friedrich, APSW 1.jpg";
import heather_johnson from "../images/therapists/Heather Johnson, APSW 1.jpg";
import skylar_krople from "../images/therapists/Skylar Krople, LPC-IT 1.jpg";
import taylor_leibfried from "../images/therapists/Taylor Leibfried, LCSW 1.jpg";
import scott_marquardt from "../images/therapists/SM-2.jpg";
import sabrina_meichtry from "../images/therapists/Sabrina Meichtry, APSW 1.jpg";
import nicolette_miller from "../images/therapists/Nicolette Miller, LCSW 1.jpg";
import kasidie_pallansch from "../images/therapists/Kasidie Pallansch, APSW 1.jpg";
import brielle_powers from "../images/therapists/Brielle Powers, LCSW 1.jpg";
import kimberly_olson from "../images/therapists/Kimberly Olson, LPC 1.jpg";
import betsy_reed from "../images/therapists/Betsy Reed, LCSW 1.jpg";
import stephanie_sisson from "../images/therapists/Stephanie Sisson, LCSW 1.jpg";
import heidi_schildt from "../images/therapists/Heidi Schildt, LPC 1.jpg";
import samantha_everson from "../images/therapists/sam-everson-apsw.jpg";
import kelly_engel from "../images/therapists/Kelly Engel-1.jpg";
import keiko_engel from "../images/therapists/Keiko Engel-1.jpg";
import kayce_wilson from "../images/therapists/KW-1.jpg";
import Footer from "./Footer";

function OurTherapists() {
  const [open, setOpen] = useState(false);
  const [modalBioText, setModalBioText] = useState("");
  const [modalHeaderText, setModalHeaderText] = useState("");
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  useEffect(() => {
    document.title = "Stateline MHS - Our Therapists"
  }, []);

  return (
    <>
      <div className="title-container">
        <h2>MEET OUR THERAPISTS</h2>
        <h1>
          <i>Click on a name to learn more!</i>
        </h1>
      </div>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: "75%",
            bgcolor: "background.paper",
            border: "2px solid #000",
            boxShadow: 24,
            p: 4,
          }}
        >
          <Typography id="modal-modal-title" variant="h6" component="h2">
            {modalHeaderText}
          </Typography>
          <Typography id="modal-modal-description" sx={{ mt: 2 }}>
            <span id="modal-bio-text">{modalBioText}</span>
          </Typography>
        </Box>
      </Modal>
      <div className="polaroid-container">
        <div className="polaroid">
          <div className="card-front">
            <img
              alt="Stephanie Knueppel"
              src={stephanie_knueppel}
              loading="eager"
            ></img>
            <div className="card-front-name">
              <Button
                border="none"
                outline="none"
                style={{ width: "100%" }}
                onClick={() => {
                  setModalHeaderText(
                    "Stephanie Knueppel (she/her), LPC, Owner, CEO"
                  );
                  setModalBioText(
                    "Stephanie is the Owner and CEO of Stateline Mental Health Services and works as a full-time therapist. Stephanie received her B.A. in Psychology and Sociology from Transylvania University. She later attended the University of Kentucky where she received her M.S. in Counseling Psychology. Stephanie is originally from Kentucky but has been in Wisconsin since 2003. She has been a resident of Beloit since 2010 and strives to support the Beloit Community by being involved in many aspects. Stephanie has held numerous positions in the counseling field throughout Kentucky and Wisconsin. Her trainings include eye movement desensitization and reprocessing (EMDR), Christian counseling, behavior modification, and brief solution focused therapy. She sees individuals of all ages, couples, and families who present with ADHD, anxiety, panic disorders, autism spectrum disorders, depression, blended family issues, trauma, and women's issues. Stephanie has experience working with Family Court and reunification therapy. In addition to her therapy services, Stephanie oversees the entire clinic and manages the business. "
                  );
                  handleOpen();
                }}
              >
                <p className="polaroid-name">Stephanie Knueppel, LPC</p>
              </Button>
            </div>
          </div>
        </div>
        <div className="polaroid">
          <div className="card-front">
            <img alt="Kelley Everson" src={kelly_everson} loading="lazy"></img>
            <div className="card-front-name">
              <Button
                border="none"
                outline="none"
                style={{ width: "100%" }}
                onClick={() => {
                  setModalHeaderText(
                    "Kelley Everson (she/her), LPC, Practice Manager"
                  );
                  setModalBioText(
                    "Kelley joined the Stateline Mental Health Services team in August 2015. Originally, Kelley started as a part-time therapist and is now one of our full-time therapists and Practice Manager. She received her B.S. in Sociology/Criminal Justice from Northern Illinois University. She then attended the University of Wisconsin-Whitewater where she received her M.S. in Counselor Education and later added an emphasis in School Counseling. Kelley has spent 13 years working in Human Services/Juvenile Justice as well as 16 years of experience working as a full-time school counselor. She has also worked as an adjunct professor in a counselor education program. Kelley specializes in abuse, trauma, ADHD, adjustment disorders, anger management, anxiety and panic disorders, mood disorders, OCD, and PTSD. She has become involved in working with first responders experiencing PTSD in the community. Kelley has experience working with different court processes including Juvenile Court, Criminal Court, Family Court and sex offender therapy. Kelley’s trainings include training in cognitive behavioral therapy (CBT), eye movement desensitization and reprocessing (EMDR), crisis intervention, and grief/loss counseling. Not only does Kelley serve clients in a therapeutic setting, but she also takes on administrative tasks related to our School Based Mental Health programs, and provides trainings to organizations about mental health, trauma, and stress management."
                  );
                  handleOpen();
                }}
              >
                <p className="polaroid-name">Kelley Everson, LPC</p>
              </Button>
            </div>
          </div>
        </div>
        <div className="polaroid">
          <div className="card-front">
            <img alt="Sam Ball" src={sam_ball} loading="lazy"></img>
            <div className="card-front-name">
              <Button
                border="none"
                outline="none"
                style={{ width: "100%" }}
                onClick={() => {
                  setModalHeaderText("Sam Ball (she/her), LCSW");
                  setModalBioText(
                    "Sam rejoined the Stateline Mental Health Services in June of 2022 as a full-time therapist and started supervising in training advance practioner social workers in November of 2022. Sam received her BA in Social Work from University of Wisconsin-Whitewater and her M.S.W. at George Williams College of Aurora. Her professional experience includes working in an intensive outpatient setting, administering psychological tests, and working in private practice. Sam has experience working with anxiety, depression, ADHD, mood disorders, trauma and PTSD. Sam has trainings that include Eye-movement desensitization and reprocessing (EMDR) Including Flash EMDR, Dialectical Behavioral Therapy (DBT), Internal Family Systems (IFS) and Cognitive Behavioral Therapy (CBT). Sam provides School Based Mental Health services in the Evansville School District as well as telehealth services for individuals."
                  );
                  handleOpen();
                }}
              >
                <p className="polaroid-name">Sam Ball, LCSW</p>
              </Button>
            </div>
          </div>
        </div>
        <div className="polaroid">
          <div className="card-front">
            <img alt="Jenniffer Beu" src={jenniffer_beu} loading="lazy"></img>
            <div className="card-front-name">
              <Button
                border="none"
                outline="none"
                style={{ width: "100%" }}
                onClick={() => {
                  setModalHeaderText("Jenniffer Beu, LPC");
                  setModalBioText(
                    "Jenniffer joined the SMHS team in June of 2017 as a full-time therapist where she began working towards her LPC. She graduated with a B.A. in Theology and a M.S. in Counseling from Concordia University Wisconsin. Jenniffer is now a Licensed Professional Counselor. Her experience as a parent, co-parent, wife, sister and daughter offer insight to all the roles we play and the struggles we face on a daily basis. Jenniffer has experience working with anger management, personality disorders, anxiety, depression, and PTSD. In addition, her education and experience with AODA matters has given her a passion to meet people where they are and help figure out where to go from there. She looks forward to working with adolescents, individuals, couples and groups on the journey to better mental health. Jenniffer has also been trained in eye movement desensitization and reprocessing (EMDR)."
                  );
                  handleOpen();
                }}
              >
                <p className="polaroid-name">Jenniffer Beu, LPC</p>
              </Button>
            </div>
          </div>
        </div>
        <div className="polaroid">
          <div className="card-front">
            <img
              alt="Audrey Buchanan"
              src={audrey_buchanan}
              loading="eager"
            ></img>
            <div className="card-front-name">
              <Button
                border="none"
                outline="none"
                style={{ width: "100%" }}
                onClick={() => {
                  setModalHeaderText("Audrey Buchanan, APSW");
                  setModalBioText(
                    "Audrey joined SMHS in June of 2022 as a part-time therapist. Audrey received her B.A. in social work from UW-Whitewater, followed by her M.S.W. from UW-Milwaukee. She received certification in mental health and school social work, with a post-masters certification in Trauma-Informed Care. Audrey has experience working with children, adolescents, and adults in the inpatient units of two different hospital settings, including the mental health units and eating disorder units. In addition to this experience, Audrey assessed patients with mental health and AODA needs in the emergency department of a local hospital. Audrey has experience working with people who have ADHD, anxiety, depression, mood disorders, personality disorders, eating disorders, psychoses, and trauma. She currently works full-time for the School District of Beloit as a School Social Worker at the district’s alternative high school. She works with people of all ages, from four-years old through the lifespan, but is most passionate about working with adolescents, especially those who have suffered trauma. Audrey is seeing clients in the clinic."
                  );
                  handleOpen();
                }}
              >
                <p className="polaroid-name">Audrey Buchanan, APSW</p>
              </Button>
            </div>
          </div>
        </div>
        <div className="polaroid">
          <div className="card-front">
            <img alt="Kenzie Dodge" src={kenzie_dodge} loading="lazy"></img>
            <div className="card-front-name">
              <Button
                border="none"
                outline="none"
                style={{ width: "100%" }}
                onClick={() => {
                  setModalHeaderText("Kenzie Dodge, APSW");
                  setModalBioText(
                    "Kenzie joined Stateline Mental Health Services in February of 2023. Kenzie received her masters degree in social work from George Williams College of Aurora University. Prior to working at Stateline, Kenzie has experience in residential program at Rogers and working for PSG working with different family systems and school settings. She has experience working with clients with anxiety, depression, ADHD, Autism Spectrum Disorders, PTSD, trauma, and is LGBTQ+ informed. Kenzie is currently a school-based therapist in the Clinton Community School District."
                  );
                  handleOpen();
                }}
              >
                <p className="polaroid-name">Kenzie Dodge, APSW</p>
              </Button>
            </div>
          </div>
        </div>
        <div className="polaroid">
          <div className="card-front">
            <img
              alt="Nicholas Dubois-Rose"
              src={nicholas_dubois_rose}
              loading="lazy"
            ></img>
            <div className="card-front-name">
              <Button
                border="none"
                outline="none"
                style={{ width: "100%" }}
                onClick={() => {
                  setModalHeaderText("Nicholas Dubois-Rose, LCSW");
                  setModalBioText(
                    "Nick joined Stateline Mental Health Services in February 2023 as a full-time therapist both in the clinic and in the school setting. He graduated with his B.A. and B.S.W. in 2019 from George Williams College. Nick continued his education at George Williams College and received his M.S.W. in 2020. Nick has experience working with children in the school, clinic, and home settings. He has experience with anxiety disorders, ADHD, Autism spectrum disorder, depressive disorders, case management, and behavior modification. Nick is currently serving the Delavan-Darien school district as well as the Clinic."
                  );
                  handleOpen();
                }}
              >
                <p className="polaroid-name">Nicholas Dubois-Rose, LCSW</p>
              </Button>
            </div>
          </div>
        </div>
        <div className="polaroid">
          <div className="card-front">
            <img
              alt="Jennifer Ellenson"
              src={jennifer_ellenson}
              loading="lazy"
            ></img>
            <div className="card-front-name">
              <Button
                border="none"
                outline="none"
                style={{ width: "100%" }}
                onClick={() => {
                  setModalHeaderText("Jennifer Ellenson, LPC-IT");
                  setModalBioText(
                    "Jennifer joined Stateline Mental Health Services team in March of 2023 as an intern and began practicing as a LPC-IT in May 2023. Jennifer received her B.S. in Sociology from the University of Wisconsin – La Crosse and received her M.S. in Counselor Education with an emphasis in Community Counseling at the University of Wisconsin – Whitewater. Jennifer spent 21 years working with victims of domestic violence in the criminal justice system as a victim/witness counselor. Throughout Jennifer’s career in the criminal justice system, she also worked with families of homicide victims. Jennifer has experience working with domestic violence, trauma, anxiety and depressive disorders, mood disorders, self-esteem and relationship/family issues. Currently, Jennifer provides in-clinic services using cognitive behavioral therapy, client-centered and strength-based approach. Jennifer works with adolescents, adults and couples."
                  );
                  handleOpen();
                }}
              >
                <p className="polaroid-name">Jennifer Ellenson, LPC-IT</p>
              </Button>
            </div>
          </div>
        </div>
        <div className="polaroid">
          <div className="card-front">
            <img alt="Keiko Engel" src={keiko_engel} loading="lazy"></img>
            <div className="card-front-name">
              <Button
                border="none"
                outline="none"
                style={{ width: "100%" }}
                onClick={() => {
                  setModalHeaderText("Keiko Engel, APSW");
                  setModalBioText(
                    "Keiko joined Stateline Mental Health Services in May 2023. Keiko graduated from the University of Wisconsin-Madison with a Bachelors of Social Work as well as the University of Wisconsin-Milwaukee with her Masters of Social Work. The populations Keiko works with are children, teenagers, and adults. Her work primarily covers depression, anxiety, trauma, grief, and eating disorders. Keiko offers in-clinic services, telehealth services, and school-based services in Elkhorn Area School District and Milton School District."
                  );
                  handleOpen();
                }}
              >
                <p className="polaroid-name">Keiko Engel, APSW</p>
              </Button>
            </div>
          </div>
        </div>
        <div className="polaroid">
          <div className="card-front">
            <img alt="Kelly Engel" src={kelly_engel} loading="lazy"></img>
            <div className="card-front-name">
              <Button
                border="none"
                outline="none"
                style={{ width: "100%" }}
                onClick={() => {
                  setModalHeaderText("Kelly Engel, APSW");
                  setModalBioText(
                    "Kelly joined the SMHS team in August of 2023. Kelly earned her Bachelors Degree in Social Work from the UW-Whitewater in 2016. She then went on to earn her Master's Degree in Social Work from George Williams college in 2018. Her personal experience includes work in in hospice, adults with disabilities, active duty military, inpatient psychiatry, and outpatient therapy. Kelly is passionate about working with children, teens, and adults as individuals, dyadically, or in groups. She specializes in mood disorders, anxiety, depression, trauma, family conflict, and much more. Kelly enjoys utilizing cognitive-behavior therapy, attachment-based interventions, positive psychology, and theraplay interventions."
                  );
                  handleOpen();
                }}
              >
                <p className="polaroid-name">Kelly Engel, APSW</p>
              </Button>
            </div>
          </div>
        </div>
        <div className="polaroid">
          <div className="card-front">
            <img
              alt="Samantha Everson"
              src={samantha_everson}
              loading="lazy"
            ></img>
            <div className="card-front-name">
              <Button
                border="none"
                outline="none"
                style={{ width: "100%" }}
                onClick={() => {
                  setModalHeaderText("Samantha Everson, APSW");
                  setModalBioText(
                    "Samantha joined the SMHS team in November of 2023. She has a Bachelors in Psychology from Carroll University in 2021 and received her Masters in Social Work Degree from UW-Milwaukee in 2023. Samantha has a background working with trauma, depression, and anxiety. She has experiencing working with Autism and Developmental Disorders. She uses CBT, strength based, and motivational interviewing treatment modalities and had a trauma informed care certificate from UW-Milwaukee. She will be supporting clients in the clinic and through telehealth."
                  );
                  handleOpen();
                }}
              >
                <p className="polaroid-name">Samantha Everson, APSW</p>
              </Button>
            </div>
          </div>
        </div>
        <div className="polaroid">
          <div className="card-front">
            <img alt="David Fischer" src={david_fischer} loading="eager"></img>
            <div className="card-front-name">
              <Button
                border="none"
                outline="none"
                style={{ width: "100%" }}
                onClick={() => {
                  setModalHeaderText("David Fischer (he/him), LCSW");
                  setModalBioText(
                    "David joined Stateline Mental Health Services in September of 2020 as a full-time therapist. David received his Master's Degree from Aurora University at George Williams College. David is a Licensed Clinician Social Worker since 2014 and has a specialty in Substance Abuse since 2015. He has work experience with substance abuse programs including intensive outpatient program, residential, and community support programs. David has experience working with diverse populations ages 16 and older treating depression, anxiety, PTSD, ADHD, grief and loss, personality disorders, OCD, panic disorders, anger management, family therapy, and couples therapy. David uses interventions such as CBT, DBT, motivational interviewing, Illness Recovery Management, and Substance Abuse Relapse Prevention. David provides these supports within the clinic. In addition to providing services in the clinic, David oversees advance practioner social workers and helps supervise while they are working towards their licensure."
                  );
                  handleOpen();
                }}
              >
                <p className="polaroid-name">David Fischer, LCSW</p>
              </Button>
            </div>
          </div>
        </div>
        <div className="polaroid">
          <div className="card-front">
            <img alt="Garth Fisher" src={garth_fisher} loading="lazy"></img>
            <div className="card-front-name">
              <Button
                border="none"
                outline="none"
                style={{ width: "100%" }}
                onClick={() => {
                  setModalHeaderText("Garth Fisher, LCSW");
                  setModalBioText(
                    "G​arth has been with Stateline Mental Health since January 2017 as a part time therapist. He has a M.S.W from Indiana University and has spent his career studying the process of human change. He has studied Biofeedback, Meditation, Wilderness Therapy, Sound Therapy, Massage Therapy, Martial Arts, and Relationship Therapy. He practices a client centered form of therapy that seeks to define the presenting problem clearly and facilitate the client's inner strengths and skills to find a solution. He takes into consideration physical problems, life style issues regarding health, and environmental issues that may be impacting the presenting problem. Garth specializes in abuse, anger management, ADHD, mood disorders, dissociative disorders, grief, hypnosis, military treatment, OCD, pain management, and phobias. He works with adults and couples using an eclectic approach based on his years of experience."
                  );
                  handleOpen();
                }}
              >
                <p className="polaroid-name">Garth Fisher, LCSW</p>
              </Button>
            </div>
          </div>
        </div>
        <div className="polaroid">
          <div className="card-front">
            <img
              alt="Lindsey Friedrich"
              src={lindsey_friedrich}
              loading="lazy"
            ></img>
            <div className="card-front-name">
              <Button
                border="none"
                outline="none"
                style={{ width: "100%" }}
                onClick={() => {
                  setModalHeaderText("Lindsey Friedrich (she/her), APSW");
                  setModalBioText(
                    "Lindsey joined Stateline Mental Health Services in January of 2022 as a full-time therapist. She received her B.S. from University of Wisconsin - Stevens Point in Psychology and continued her education at the University of Wisconsin -Whitewater where she received her Master's of Social Work. Lindsey previously worked for the Department of Human Services in the Behavioral Health division working with clients of all ages and backgrounds. Lindsey takes a holistic approach when working with clients and is passionate about providing individualized care. She specializes in anxiety disorders, depressive disorders, ADHD, personality disorders, trauma and mood disorders. Lindsey is fully based in the clinic and works with adolescents, adults and couples."
                  );
                  handleOpen();
                }}
              >
                <p className="polaroid-name">Lindsey Friedrich, APSW</p>
              </Button>
            </div>
          </div>
        </div>
        <div className="polaroid">
          <div className="card-front">
            <img
              alt="Heather Johnson"
              src={heather_johnson}
              loading="lazy"
            ></img>
            <div className="card-front-name">
              <Button
                border="none"
                outline="none"
                style={{ width: "100%" }}
                onClick={() => {
                  setModalHeaderText("Heather Johnson (she/her), APSW");
                  setModalBioText(
                    "Heather joined SMHS in August of 2022 as a student intern. Heather was hired on as a full-time therapist in March of 2023 after receiving her APSW. She graduated with her Bachelor's Degree in Human Services in 2009 and went on to complete her Master's Degree in Social Work at Northcentral University in 2023. Heather has spent the last 14 years working with adults and their support systems in case management, home health, and hospice settings. She is passionate about working with adolescents and young adults. In her practice, Heather likes to meet client's where they are at while supporting their individual strengths to help them grow by using mindfulness techniques. She has trainings in Cognitive Behavioral Therapy (CBT), Dialectical Behavioral Therapy (DBT), and Motivational Interviewing. Her experience includes working with anxiety, depression, ADHD, Autism Spectrum Disorder, and grief/loss. She is LGBTQ+ supporting. Currently, Heather is in the school districts of Brodhead and Parkview and sees client's in the clinic."
                  );
                  handleOpen();
                }}
              >
                <p className="polaroid-name">Heather Johnson, APSW</p>
              </Button>
            </div>
          </div>
        </div>
        <div className="polaroid">
          <div className="card-front">
            <img alt="Skylar Krople" src={skylar_krople} loading="lazy"></img>
            <div className="card-front-name">
              <Button
                border="none"
                outline="none"
                style={{ width: "100%" }}
                onClick={() => {
                  setModalHeaderText("Skylar Krople (she/her), LPC");
                  setModalBioText(
                    "Skylar joined SMHS in July of 2021 as a full-time therapist. She currently provides therapy to our clients via telehealth . Skylar's education and training includes a B.A. in Psychology and Organizational Communication from North Central College and a M.A. in Clinical Mental Health Counseling from the University of Iowa. Skylar's background includes working with children, adolescents, and adults in residential, outpatient, and school-based settings. Skylar enjoys working with individuals presenting with anxiety disorders, mood disorders, substance use disorders, PTSD, and those with concerns related to trauma/abuse, self-esteem, identity, life transitions, family dynamics, and romantic relationships. Skylar practices from a non-judgmental, authentic, and person-centered approach that prioritizes forming a supportive and collaborative relationship with each client. Skylar aims to understand each individual's unique experience, needs, and strengths in order to best assist them in reaching their treatment goals. Skylar utilizes and integrates a variety of therapies including DBT, CBT, and IFS along with techniques from narrative, art, and play-based approaches."
                  );
                  handleOpen();
                }}
              >
                <p className="polaroid-name">Skylar Krople, LPC</p>
              </Button>
            </div>
          </div>
        </div>
        <div className="polaroid">
          <div className="card-front">
            <img
              alt="Taylor Leibfried"
              src={taylor_leibfried}
              loading="lazy"
            ></img>
            <div className="card-front-name">
              <Button
                border="none"
                outline="none"
                style={{ width: "100%" }}
                onClick={() => {
                  setModalHeaderText("Taylor Leibfried (she/her), LCSW");
                  setModalBioText(
                    "Taylor joined Stateline Mental Health Services in January 2019 as a full-time therapist. She graduated with her B.S. in Psychology and Interpersonal Communications from University of Wisconsin - La Crosse. Taylor went on to continue her education where she received her M.S.W. from University of Wisconsin - Milwaukee. Prior to working at SMHS, Taylor worked as mental health care coordinator at Beloit Memorial Hospital. She is passionate about working with kids from ages 5 to young adults and has experience treating anxiety, depression, anger management, impulse control, behavior modification, ADHD, mood disorders, and trauma. She is LGBTQ+ supportive. Taylor is also trained in Brainspotting. Currently, Taylor is working in the Edgerton School District as a School Based Mental Health Therapist."
                  );
                  handleOpen();
                }}
              >
                <p className="polaroid-name">Taylor Leibfried, LCSW</p>
              </Button>
            </div>
          </div>
        </div>
        <div className="polaroid">
          <div className="card-front">
            <img
              alt="Scott Marquardt"
              src={scott_marquardt}
              loading="lazy"
            ></img>
            <div className="card-front-name">
              <Button
                border="none"
                outline="none"
                style={{ width: "100%" }}
                onClick={() => {
                  setModalHeaderText("Scott Marquardt, LPC-IT");
                  setModalBioText(
                    "Scott Marquardt joined the Stateline Mental Health Services team full time in February 2024. He graduated with a B.A. in Criminal Justice and an M.S. in Education with a mental health counseling emphasis from UW-Platteville. Mental health counseling is Scott’s encore career, having recently retired after more than 31 years in law enforcement. His previous experiences as a front-line public safety worker and a leader in law enforcement have shown him first-hand the impact of life struggles and unresolved trauma on first responders and those living within the communities they serve. His mission for this new profession (his “why”) is to provide a safe, confidential, and understanding space for all those serving in high pressure and high stakes first responder and related professions. He hopes that as someone who has “been there” in a variety of ways, he can provide a level of understanding and compassion to a sometimes skeptical population that can often be reluctant to seek out help. Beyond just first responders, Scott enjoys helping teens and adults of all cultures and identities work through depression, anxiety, trauma, life transitions, work conflict, leadership struggles and other challenges. His formal training includes cognitive behavioral therapy (CBT), acceptance and commitment therapy (ACT), motivational interviewing and mindfulness techniques. However, he truly believes in a person- centered eclectic approach to give each client what they uniquely need to grow into their best selves."
                  );
                  handleOpen();
                }}
              >
                <p className="polaroid-name">Scott Marquardt, LPC-IT</p>
              </Button>
            </div>
          </div>
        </div>
        <div className="polaroid">
          <div className="card-front">
            <img
              alt="Sabrina Meichtry"
              src={sabrina_meichtry}
              loading="lazy"
            ></img>
            <div className="card-front-name">
              <Button
                border="none"
                outline="none"
                style={{ width: "100%" }}
                onClick={() => {
                  setModalHeaderText("Sabrina Meichtry, LCSW");
                  setModalBioText(
                    "Sabrina joined the Stateline Mental Health Services team in July 2021. Sabrina received her Bachelor’s Degree in social work from Viterbo University in 2001 and her Master’s Degree in social work with an emphasis in mental health from the University of Madison in 2006. Sabrina’s areas of focus through the years have included children and families, chronic & terminal illnesses, caregiving, faith, grief & loss, eating disorders, ADHD, anxiety, OCD, co-parenting and relationship stresses. She has training in Cognitive Behavioral Therapy (CBT), Dialectical Behavioral Therapy (DBT), Internal Family Systems (IFS), Supportive Parenting of Anxious Childhood Emotions (SPACE) and Eye Movement Desensitization and Reprocessing (EMDR). Sabrina provides in person School Based Mental Health Services within all three Brodhead School District buildings."
                  );
                  handleOpen();
                }}
              >
                <p className="polaroid-name">Sabrina Meichtry, LCSW</p>
              </Button>
            </div>
          </div>
        </div>
        <div className="polaroid">
          <div className="card-front">
            <img
              alt="Nicolette Miller"
              src={nicolette_miller}
              loading="lazy"
            ></img>
            <div className="card-front-name">
              <Button
                border="none"
                outline="none"
                style={{ width: "100%" }}
                onClick={() => {
                  setModalHeaderText("Nicolette Miller, LCSW");
                  setModalBioText(
                    "Nikki joined the Stateline Mental Health Services team in June of 2020 as a full-time therapist. She received her B.A. from the University of Wisconsin-La Crosse in Psychology. Nikki continued her education and received her MSW from the University of Wisconsin-Whitewater. Nikki has experience working with children and adolescents in outpatient, school, community, and home-based settings. She has experience with anxiety disorders, depressive disorders, personality disorders, ADHD, trauma, self-esteem, mood disorders, and relationship/family issues. Currently, Nikki provides in-clinic services as well as school-based services in the Elkhorn School District. Nikki provides a holistic, client-centered and strengths-based approach and is passionate about providing clients with space for self-determination in treatment."
                  );
                  handleOpen();
                }}
              >
                <p className="polaroid-name">Nicolette Miller, LCSW</p>
              </Button>
            </div>
          </div>
        </div>
        <div className="polaroid">
          <div className="card-front">
            <img
              alt="Kasidie Pallansch"
              src={kasidie_pallansch}
              loading="lazy"
            ></img>
            <div className="card-front-name">
              <Button
                border="none"
                outline="none"
                style={{ width: "100%" }}
                onClick={() => {
                  setModalHeaderText("Kasidie Pallansch, APSW");
                  setModalBioText(
                    "Kasidie joined Stateline Mental Health Services in June 2022 as a full-time therapist working towards her LCSW. Kasidie received her B.A. from the University of Wisconsin- Whitewater in Social Work. Kasidie continued her education and received her M.S.W. from Aurora University. Previously, Kasidie worked in the child welfare field and as an Outpatient Pediatric Social Worker for SSM. She has case management experience working with children, adolescents, and families. Additionally, Kasidie has experience working with trauma/abuse, anxiety disorders, ADHD, depressive disorders, behavior modification, substance use disorders, co-occuring disorders, and relationship/family issues. She has also been trained in Crisis Intervention. Currently, Kasidie provides services in the clinic and Milton School District."
                  );
                  handleOpen();
                }}
              >
                <p className="polaroid-name">Kasidie Pallansch, APSW</p>
              </Button>
            </div>
          </div>
        </div>
        <div className="polaroid">
          <div className="card-front">
            <img alt="Brielle Powers" src={brielle_powers} loading="lazy"></img>
            <div className="card-front-name">
              <Button
                border="none"
                outline="none"
                style={{ width: "100%" }}
                onClick={() => {
                  setModalHeaderText("Brielle Powers (she/her), LCSW");
                  setModalBioText(
                    "Brielle joined Stateline Mental Health Services in August of 2020 as a full-time therapist. Brielle received her Master's degree from George Williams College of Aurora University. Her clinical experience includes working with children, adolescents and adults in the outpatient setting. She is a Brainspotting practitioner trained in phases 1 and 2. Brielle practices from a holistic point of view looking at each individual’s unique needs and goals for counseling. Brielle specializes in working with individuals with anxiety, depression, relationship challenges and PTSD and has a passion for working with individuals within the LGBTQ+ population. Brielle provides services within the clinic setting."
                  );
                  handleOpen();
                }}
              >
                <p className="polaroid-name">Brielle Powers, LCSW</p>
              </Button>
            </div>
          </div>
        </div>
        <div className="polaroid">
          <div className="card-front">
            <img alt="Kimberly Olson" src={kimberly_olson} loading="lazy"></img>
            <div className="card-front-name">
              <Button
                border="none"
                outline="none"
                style={{ width: "100%" }}
                onClick={() => {
                  setModalHeaderText("Kimberly Olson, LPC");
                  setModalBioText(
                    "Kim joined Stateline Mental Health Services in June of 2022 as a full-time therapist. She graduated from UW-Stout in 2014 with her MS in Clinical Mental Health Counseling. Kim has been a Licensed Professional Counselor (LPC) since 2017, and has been working in the mental health field for over 10 years in various roles, including case management, Community Support Programs (CSP), Program for Assertive Community Treatment (PACT), Crisis Intervention, and Outpatient Therapy. She is passionate about working with children ages 4 and older, all the way to adults in later life stages, as well as parents, families, and couples, and individuals. She specializes in mood disorders, anxiety and depression, trauma, family conflict, and utilizes cognitive-behavior therapy, attachment-based interventions, positive psychology, and behavior modification. She is currently working in the school districts as well as in the clinic."
                  );
                  handleOpen();
                }}
              >
                <p className="polaroid-name">Kimberly Olson, LPC</p>
              </Button>
            </div>
          </div>
        </div>
        <div className="polaroid">
          <div className="card-front">
            <img alt="Betsy Reed" src={betsy_reed} loading="lazy"></img>
            <div className="card-front-name">
              <Button
                border="none"
                outline="none"
                style={{ width: "100%" }}
                onClick={() => {
                  setModalHeaderText("Betsy Reed, LCSW");
                  setModalBioText(
                    "Betsy joined the Stateline Mental Health Services team in November of 2021 as a full-time therapist. She received her B.A. from the University of Wisconsin-Whitewater in Social Work. Betsy continued her education and received her M.S.W. from Barry University in Miami Shores, Florida. Prior to working at Stateline Mental Health Services, Betsy has had previous clinical experience working both in the outpatient setting with children and adults, as well as within an intensive outpatient program for children and adolescents. Betsy has experience treating anxiety disorders, depressive disorders, personality disorders, ADHD, trauma, Autism Spectrum Disorder, and behavior modification. Currently, Betsy works as a School Based Mental Health Therapist in the Beloit Turner School District and works in the clinic."
                  );
                  handleOpen();
                }}
              >
                <p className="polaroid-name">Betsy Reed, LCSW</p>
              </Button>
            </div>
          </div>
        </div>

        <div className="polaroid">
          <div className="card-front">
            <img
              alt="Stephanie Sisson"
              src={stephanie_sisson}
              loading="lazy"
            ></img>
            <div className="card-front-name">
              <Button
                border="none"
                outline="none"
                style={{ width: "100%" }}
                onClick={() => {
                  setModalHeaderText("Stephanie Sisson, LCSW");
                  setModalBioText(
                    "Stephanie joined Stateline Mental Health Services in June of 2020. In 2012, she received her Masters Degree in social work at the University of Utah. She has worked in a variety of helping professions for the past 15 years and has spent the past 8 years working in home health, hospice and palliative care as a Medical Social Worker. She has practiced social work in Utah, New Mexico and Wisconsin and loves experiencing variations in culture and terrain. Stephanie specializes in treating depression and anxiety in older adults and those who suffer with chronic conditions. Stephanie also has experience with grief and loss as well as trauma and PTSD. She has worked with children in after school programs as well as at risk youth in residential treatment centers. She has supervised several graduate and undergraduate Social Work students and has really enjoyed teaching what she has learned as a mental health practitioner. Stephanie sees clients in the clinic."
                  );
                  handleOpen();
                }}
              >
                <p className="polaroid-name">Stephanie Sisson, LCSW</p>
              </Button>
            </div>
          </div>
        </div>
        <div className="polaroid">
          <div className="card-front">
            <img alt="Heidi Schildt" src={heidi_schildt} loading="lazy"></img>
            <div className="card-front-name">
              <Button
                border="none"
                outline="none"
                style={{ width: "100%" }}
                onClick={() => {
                  setModalHeaderText("Heidi Schildt (she/her), LPC");
                  setModalBioText(
                    "Heidi joined Stateline Mental Health Services in August 2016 as a part-time therapist. She received her B.S. in Psychology from the University of Wisconsin-Madison and her M.S. in Counselor Education from the University of Wisconsin-Oshkosh. She also works full-time as a school counselor with over 30 years of experience, and as an adjunct professor in the Counselor Education Department for Concordia University Wisconsin. Heidi is trained in behavioral health assessment, behavior modification, cognitive behavioral therapy, couples and family therapy, and intervention services. Heidi has experience working with people who struggle with abuse, ADHD, anger management, anxiety, depression, grief, mood disorders, personality disorders, relationship problems, trauma and is LGBTQ+ informed. Heidi sees clients in the clinic."
                  );
                  handleOpen();
                }}
              >
                <p className="polaroid-name">Heidi Schildt, LPC</p>
              </Button>
            </div>
          </div>
        </div>
        <div className="polaroid">
          <div className="card-front">
            <img alt="Kassandra Wilson" src={kayce_wilson} loading="lazy"></img>
            <div className="card-front-name">
              <Button
                border="none"
                outline="none"
                style={{ width: "100%" }}
                onClick={() => {
                  setModalHeaderText("Kassandra Wilson, APSW");
                  setModalBioText(
                    "Kayce joined the SMHS team in May of 2024 as a full-time therapist. She graduated with a bachelor's degree in social work from the University of Whitewater and a master's degree in social work from Aurora University. Kayce is currently licensed as an Advanced Practice Social Worker, and she is working towards being licensed as a Licensed Clinical Social Worker. Kayce comes with eleven years of experience in child welfare. She comes with experience working with clients who struggle with anxiety, depression, self-harm, anger management, and AODA. Her work and experiences with co-parenting and parenting matters drives her passion in working with parents and their children to guide them through their journey. Kayce works with children, individuals, couples, and also works with students at The Lincoln Academy. "
                  );
                  handleOpen();
                }}
              >
                <p className="polaroid-name">Kassandra Wilson, APSW</p>
              </Button>
            </div>
          </div>
        </div>
      </div>
      

      <div className="ot-filler-container"></div>

      <Footer />
    </>
  );
}

export default OurTherapists;
